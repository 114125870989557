import React from "react";
import PreadOnlyRow from "./PreadOnlyRow";
import PeditableRow from "./PeditableRow";
import { useState, useEffect } from "react";
import "../Pagination/Pagination.css";
import Loader from "../Loader/Loader";

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function PortfolioDetails({ token }) {
  const [groupDescdata, setGroupDescdata] = useState([]);
  const [groupdesc, setGroupdesc] = useState("");
  const [divPayFqData, setDivPayFrequencyData] = useState([]);
  const [divPayfq, setDivPayFq] = useState("");
  const [divpayDateData, setDivPayDateData] = useState([]);
  const [divPayDat, setDivPayDat] = useState("");
  const [editrowId, setEditRowId] = useState("");
  const [data, setData] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [fundType, setFundType] = useState("PFI");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  let fundValue = "PFI";
  //State managed for pagination
  let pages = Math.ceil(data.length / 30);

  let paging = [];
  for (let i = 1; i <= pages; i++) {
    paging.push(i);
  }

  function goToNextPage() {
    if (currentPage < pages) {
      setCurrentPage((page) => page + 1);
    }
  }

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((page) => page - 1);
    }
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = (filterResponse) => {
    let startIndex = currentPage * 30 - 30;
    let endIndex = startIndex + 30;
    return searchVal===""?data.slice(startIndex, endIndex):filterResponse.slice(startIndex,endIndex)
  };

  //onchange of groupDescription
  const handleGroupDescription = (e) => {
    e.preventDefault();
    setGroupdesc(e.target.value);
  };

  //Onchange of Div pay frequency
  const handleDivPayFrequency = (e) => {
    e.preventDefault();
    setDivPayFq(e.target.value);
  };

  //Onchange of Div Pay Date
  const handleDivPayDate = (e) => {
    e.preventDefault();
    setDivPayDat(e.target.value);
  };
  //onclick of edit button
  const portfolioeditHandler = (
    e,
    id,
    diV_GRP_CD,
    diV_PAY_DT_CD,
    diV_CALC_FQCY_CD
  ) => {
    e.preventDefault();
    setEditRowId(id);
    getDivPayDate();
    getDivPayFrequency();
    getGroupDescription();
    setGroupdesc(diV_GRP_CD);
    setDivPayFq(diV_CALC_FQCY_CD);
    setDivPayDat(diV_PAY_DT_CD);
  };

  //onclick of cancel button
  const portfolioCancelHandler = (e) => {
    e.preventDefault();
    setEditRowId();
  };

  //Onclick of save button
  const portfoliohandleSave = (e, item) => {
    setLoading(true);
    e.preventDefault();
    fetch(`${baseUrl}/UpdatePortfolioFundDataAPI`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        exT_PRC_FUND_NMBR: item.exT_PRC_FUND_NMBR,
        diV_GRP_CD: groupdesc!==""?groupdesc:groupDescdata[0].diV_GRP_CD,
        cptL_GN_FQCY_CD: item.cptL_GN_FQCY_CD,
        cptL_GN_PAY_DT_CD: item.cptL_GN_PAY_DT_CD,
        diV_CALC_FQCY_CD: divPayfq,
        diV_PAY_DT_CD: divPayDat,
        pcR_FD_PTF_ID: item.pcR_FD_PTF_ID,
      }),
    }).then((res) => {
      if (res) {
        fundValue = fundType;
        getDivPayDate();
        getDivPayFrequency();
        getGroupDescription();
        getPortfolioDetailsData();
        setLoading(false);
      } else {
        console.log("else new post resp", res);
      }
    });
    setEditRowId();
  };

  let filterResponse = [];
  //Search Functionality
  const searchKeyword = (rows) => {
    if (searchVal !== "") {
      filterResponse = data.filter((value) => {
        return (
          value.fD_PTF_NM
            .toString()
            .toLowerCase()
            .indexOf(searchVal.toLowerCase()) > -1 ||
          value.diV_GRP_TXT
            .toString()
            .toLowerCase()
            .indexOf(searchVal.toLowerCase()) > -1 ||
          value.exT_PRC_FUND_NMBR
            .toString()
            .toLowerCase()
            .indexOf(searchVal.toLowerCase()) > -1 ||
          value.cgDIVIDEND_FQCY_TXT
            .toString()
            .toLowerCase()
            .indexOf(searchVal.toLowerCase()) > -1 ||
          value.cgPAY_DT_TXT
            .toString()
            .toLowerCase()
            .indexOf(searchVal.toLowerCase()) > -1 ||
          value.divDIVIDEND_FQCY_TXT
            .toString()
            .toLowerCase()
            .indexOf(searchVal.toLowerCase()) > -1 ||
          value.divPAY_DT_TXT
            .toString()
            .toLowerCase()
            .indexOf(searchVal.toLowerCase()) > -1
        );
      });
      pages = Math.ceil(filterResponse.length / 30);
      paging = [];
      for (let i = 1; i <= pages; i++) {
        paging.push(i);
      }
      return getPaginatedData(filterResponse);
    }
    return getPaginatedData(data)
  };

  //Dropdown for groupdescription
  const getGroupDescription = () => {
    fetch(`${baseUrl}/FetchGroupDescriptionAPI `, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((r) => r.json())
      .then((res) => {
        if (res) {
          setGroupDescdata(res.records);
        } else {
          console.log("else new post resp", res);
        }
      });
  };

  //Dropdown Data for Div Pay frequency
  const getDivPayFrequency = () => {
    fetch(`${baseUrl}/FetchDivPayFrequencyDataAPI`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((r) => r.json())
      .then((res) => {
        if (res) {
          setDivPayFrequencyData(res.records);
        } else {
          console.log("else new post resp", res);
        }
      });
  };

  //Dropdown for Div pay Date Data
  const getDivPayDate = () => {
    fetch(`${baseUrl}/FetchDivPayDateAPI`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((r) => r.json())
      .then((res) => {
        if (res) {
          setDivPayDateData(res.records);
        } else {
          console.log("else new post resp", res);
        }
      });
  };

  //Api call to get data on pageload
  const getPortfolioDetailsData = () => {
    fetch(`${baseUrl}/FetchFundDividendDataAPI?fundType=${fundValue}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((r) => r.json())
      .then((res) => {
        if (res) {
          setData(res.records);
          setLoading(false);
        } else {
          console.log("else new post resp", res);
        }
      });
  };

  const fundTypeOnChange = (e) => {
    setLoading(true);
    getDivPayDate();
    getDivPayFrequency();
    getGroupDescription();
    setFundType(e.target.value);
    fundValue = e.target.value;
    getPortfolioDetailsData();
    setCurrentPage(1)
  };

  useEffect(() => {
    getPortfolioDetailsData();
  }, []);

  return (
    <div className="container">
      <div className="headerText"> Portfolio Maintenance</div>
      <div style={{ margin: "2% 0% 0% 0%" }}>
        <span className="searchText">Fund Type:</span>
        <select
          value={fundType}
          onChange={(e) => fundTypeOnChange(e)}
          style={{ margin: "0% 1% 0% 1%" }}
        >
          <option value="PFI">PFI</option>
          <option value="PVC">PVC</option>
          <option value="INT">INT</option>
          <option value="LP">LP</option>
          <option value="REIT">REIT</option>
        </select>
      </div>
      {loading ? <Loader /> : ""}
      <div style={{ margin: "2% 0% 2% 0%" }}>
        <span className="searchText">Search Keywords:</span>
        <input
          type="text"
          value={searchVal}
          onChange={(e) => {setSearchVal(e.target.value);setCurrentPage(1)}}
          style={{ margin: "0% 1% 0% 1%" }}
        />
        <span className="searchText">ex: mid income, daily 45 20</span>
      </div>

      <table>
        <thead>
          <tr>
            <th></th>
            <th>Portfolio Number</th>
            <th>Portfolio Name</th>
            <th>Group Description</th>
            <th>CG Pay frequency</th>
            <th>CG Pay Date</th>
            <th>Div Pay frequency</th>
            <th>Div Pay Date</th>
          </tr>
        </thead>
        <tbody>
          {searchKeyword().length === 0 ? (
            <tr>
              <td colSpan={8} style={{ textAlign: "center" }}>
                No matching data found
              </td>
            </tr>
          ) : (
            searchKeyword().map((item, index) => (
              <React.Fragment key={index}>
                {editrowId === item.pcR_FD_PTF_ID ? (
                  <PeditableRow
                    item={item}
                    handleGroupDescription={handleGroupDescription}
                    groupdesc={groupdesc}
                    divPayfq={divPayfq}
                    divPayDat={divPayDat}
                    portfolioCancelHandler={portfolioCancelHandler}
                    handleDivPayDate={handleDivPayDate}
                    handleDivPayFrequency={handleDivPayFrequency}
                    divPayFqData={divPayFqData}
                    divpayDateData={divpayDateData}
                    portfoliohandleSave={portfoliohandleSave}
                    groupDescdata={groupDescdata}
                  />
                ) : (
                  <PreadOnlyRow
                    item={item}
                    portfolioeditHandler={portfolioeditHandler}
                  />
                )}
              </React.Fragment>
            ))
          )}
        </tbody>
      </table>
      <div className="paginationWrapper">
        {filterResponse.length !== 0
          ? `Showing ${
              filterResponse.length !== 0 ? (currentPage - 1) * 30 + 1 : 0
            } to 
        ${
          filterResponse.length > (currentPage - 1) * 30 + 30
            ? (currentPage - 1) * 30 + 30
            : filterResponse.length
        }
        of ${filterResponse.length} entries`
          : filterResponse.length === 0 && searchVal !== "" && data.length !== 0
          ? `Showing 0 to 0 of 0 entries (filtered from ${data.length} total entries)`
          : `Showing ${data.length !== 0 ? (currentPage - 1) * 30 + 1 : 0} to
          ${
            data.length > (currentPage - 1) * 30 + 30
              ? (currentPage - 1) * 30 + 30
              : data.length
          }
          of ${data.length} entries`}

        {paging.length > 1 ? (
          <ul class="pagination">
            <li
              className={` ${currentPage === 1 ? "disabled" : ""}`}
              onClick={goToPreviousPage}
            >
              <a href="#!">
                <i class="fa fa-angle-left"></i>
              </a>
            </li>

            {paging.map((item, index) => (
              <li
                key={index}
                onClick={changePage}
                className={` ${currentPage === item ? "active" : null}`}
              >
                <a href="#!">
                  {item} <span class="sr-only"></span>
                </a>
              </li>
            ))}
            <li
              onClick={goToNextPage}
              className={`${currentPage === pages ? "disabled" : ""}`}
            >
              <a href="#!">
                <i class="fa fa-angle-right"></i>
              </a>
            </li>
          </ul>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
